import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker, LocalizationProvider } from '@material-ui/pickers';
import momentAdapter from '@material-ui/pickers/adapter/moment';
import { api_get } from "../utils/Api";
import PropTypes from 'prop-types';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const CheckForm = (props) => {
  const { values, setValues, handleChange } = props;
  const [agencies, getAgencies] = useState([]);

  useEffect(() => {
    api_get(`agencies?perPage=50`).then((data) => {
      getAgencies(data.payload);
    });
  }, []);
  const agencyChange = (event, value) => {
    setValues({ ...values, 'agency': value ? value.id : null });
  };
  const dateChange = (value) => {
    setValues({ ...values, checkDate: value.toString() });
  };
  const handleCheckChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
  };
  return (
    <div>
      <TextField
        autoFocus
        margin="dense"
        name="numCheck"
        id="numCheck"
        label="Check Number"
        value={values.numCheck || ''}
        onChange={handleChange}
        fullWidth
      />
      <Autocomplete
        id="combo-box-demo"
        name="agency"
        options={agencies}
        value={values?.agency?.id}
        onChange={agencyChange}
        getOptionLabel={(option) => `${option.abbreviation}` !== 'null' ? `[${option.abbreviation}] ${option.fr_name}` : `${option.fr_name}`}
        renderInput={(params) => <TextField {...params}
          label="Choose an agency"
        />
        }
      />
      <TextField
        autoFocus
        margin="dense"
        name="fullname"
        id="fullname"
        label="FullName"
        value={values.fullname || ''}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        fullWidth
        label="Phone Number"
        margin="dense"
        name="phone"
        id="phone"
        onChange={handleChange}
        type="number"
        value={values.phone || ''}
      />
      <LocalizationProvider dateAdapter={momentAdapter}>
        <DatePicker
          label="Check Date"
          id="checkDate"
          value={values.checkDate}
          onChange={dateChange}
          format="MM-dd-yyyy"
          renderInput={(props) => <TextField
            {...props}
            fullWidth
            size="small"
          />}
        />
      </LocalizationProvider>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.checkPayed}
                onChange={handleCheckChange}
                value={values.checkPayed}
                name="checkPayed"
                size="small"
              />
            }
            label="checkPaid"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.checkPayable}
                onChange={handleCheckChange}
                value={values.checkPayed}
                name="checkPayable"
                size="small"
              />
            }
            label="checkPayable"
          />
        </Grid>
      </Grid>
    </div>
  );
}
CheckForm.propTypes = {
  values: PropTypes.object,
  setValues: PropTypes.func,
  handleChange: PropTypes.func,
};
export default CheckForm;
