import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import SnackBar from '../SnackBar';
import {
    TextField,
} from '@material-ui/core';
import { api_put } from 'utils/Api';

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        padding: 0,
    },
    error: {
        borderColor: 'red', // Custom error border color for the TextField
    },
}));

const RejectReport = (props) => {
    const { className, handleRejectRepport, open, itemId, service, pageLink, restore, initializeData, rowsPerPage, item, ...rest } = props;
    const classes = useStyles();
    const [openAlert, setOpenAlert] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [severity, setAlertSeverity] = React.useState('success');
    const [rejectReason, setRejectReason] = React.useState('');
    const [error, setError] = React.useState(false);

    const handleChange = (event) => {
        setRejectReason(event.target.value);
        setError(false);
    };

    const handleClose = () => {
        setOpenAlert(false);
    };

    const rejectReport = () => {
        if (!rejectReason.trim()) {
            setError(true);
            return;
        }

        if (service !== null) {
            api_put(`event-penalty-reports/${itemId}`, {
                status: "Rejected",
                rejectReason,
                eventPenalty: item?.event_penalty.id,
                description: item?.description,
                user: item?.user?.id
            }).then((result) => {
                if (result?.code !== 200 && result?.code !== 201) {
                    setMessage('Error');
                    setOpenAlert(true);
                    setAlertSeverity('error');
                } else {
                    setMessage('Report rejected');
                    setOpenAlert(true);
                    setAlertSeverity('success');
                    initializeData(rowsPerPage);
                    handleRejectRepport();
                }
            });
        }
    };

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Dialog
                open={open}
                onClose={handleRejectRepport}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Reject Report</DialogTitle>
                <DialogContent style={{ width: "450px" }}>
                    <DialogContentText id="alert-dialog-description">
                        Please add a rejecting reason
                    </DialogContentText>
                    <TextField
                        fullWidth
                        label="Reject reason"
                        name="rejectReason"
                        onChange={handleChange}
                        required
                        value={rejectReason || ""}
                        variant="outlined"
                        error={error}  // Show error if the field is empty
                        helperText={error ? 'Reject reason is required' : ''}  // Error message below the input
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRejectRepport} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={rejectReport} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <SnackBar
                open={openAlert}
                handleClose={handleClose}
                message={message}
                severity={severity}
            />
        </div>
    );
};

RejectReport.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    handleRejectRepport: PropTypes.func,
    itemId: PropTypes.number,
};

export default RejectReport;
