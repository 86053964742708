import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
    { accessor: 'id', name: 'id', label: 'Id', width: '10%', show: true },
    { accessor: 'event_penalty.penalty_detail.name', label: 'Penalty Detail', show: true },
    { accessor: 'event_penalty.event.id', label: 'Event', show: true, type: "internalEventLink" },
    { accessor: 'user', label: 'Instructor', show: true, type: 'concatTeacher' },
    { accessor: 'description', label: 'Report description', show: true },
    { accessor: 'status', name: 'Status', label: 'Status', type: 'statusColor', show: true },



];

const fieldSearchable = [
    'id',
];

const fieldFilterable = [
    { name: 'user.id', label: 'Instructor', type: 'instructorAutocomplete' },
    { name: 'eventPenalty.event.content.id', label: 'Event', type: 'eventAutocomplete' },
    { name: 'penaltyDetail.id', label: 'Penalty Details', type: 'PenaltyDetailsAutocomplete' },
    { name: 'createdAt', label: 'Created At', type: 'dateRange' },
    { name: 'status', label: 'Status', type: 'dropdown', options: [{ value: "Rejected", name: "Rejected" }, { value: "Approved", name: "Approved" }, { value: "Pending", name: "Pending" }], },


];

const PenaltyReportsList = () => {
    return (
        <TableList
            columnsList={columnsList}
            fieldSearchable={fieldSearchable}
            fieldFilterable={fieldFilterable}
            url={`event-penalty-reports`}
            exportUrl={`event-penalty-reports/export`}
            searchMessage={'Search (id)'}
            pageLink={'/teacher-settings/event-penalty-reports'}
            baseUrl={`event-penalty-reports`}
            deleteUrl={`delete/many-event-penalty-reports`}
            keyValue={"eventPenaltiesReports"}
            noShow={true}
            removeAdd={true}

            noEdit={true}
        />
    );
};

export default PenaltyReportsList;
