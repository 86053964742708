// @flow
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {

    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useParams } from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import Checkbox from '@material-ui/core/Checkbox';
import { DateTimePicker, LocalizationProvider } from '@material-ui/pickers';
import momentAdapter from '@material-ui/pickers/adapter/moment';
import Moment from 'moment';
import { AsyncPaginate } from 'react-select-async-paginate';
import theme from '../../../theme';
import mapStateToRequest from '../../../helpers/mapStateToRequest';
import FilesDropzone from '../../../components/FilesDropzone';
import { api_get, api_post, api_post_serialize } from '../../../utils/Api';
import FilesList from "../../../components/Toolbar/FilesList";
import moment from "moment";
import DescriptionField from "../../../components/DescriptionField";
import LoadingApi from 'components/LoadingApi/LoadingApi';
import { getLevels, getTeachers, getSubjects, getGroups, getWebinars } from "../../../helpers/methods";
import ShareIcon from "@material-ui/icons/Share";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
        overflow: 'visible',
    },
    mtop: {
        marginTop: 24,
    },

    mtop0: {
        marginTop: 0,
    },
    content: {
        padding: 0,
    },
    uploadedFiles: {
        marginTop: 10,
    },
}));

type Props = {
    edit: boolean,
    className: string
}

const FormEdit = (props: Props): React$Element<any> => {
    const { id } = useParams();
    const { className, edit, ...rest } = props;
    const classes = useStyles();
    const [duration, setDuration] = useState('02:00');
    const [values, setValues] = useState({
        content: {
            name: '',
            isDraft: false,
            active: false,
            instructor: '',
            reason: '',
            isEventTitleApproved: 0,
            //contentFiles: [],
            contentStudentLevels: [],
            groupContents: [],
            type: 5,
            description: undefined
        },
        startDate: Moment().format('YYYY-MM-DD HH:mm'),
        endDate: Moment().format('YYYY-MM-DD HH:mm'),
        isBigZoomEvent: false,
        isFromCalendar: false,
        webinarAccount: '',
        canceled: false,
        subjectEvents: null,
    });
    const [message, setAlertMessage] = useState('');
    const [severity, setAlertSeverity] = useState('success');
    const [webinar, setWebinar] = useState(null);
    const [attachments, setAttachments] = useState([]);
    const [group, setGroup] = useState(null)
    const [url, setUrl] = useState(null)
    const [name, setName] = useState(null)
    const [Levels, setLevels] = useState(null)
    const [teacher, setTeacher] = useState(null);
    const [subject, setSubject] = useState(null);
    const [active, setActive] = useState(null)
    const [isDraft, setIsDraft] = useState(null)
    const [loading, setLoading] = useState(false);
    const [canceled, setCancel] = useState(null);
    const [reason, setReason] = useState(null)
    const [bbbLinks, setBBBLinks] = useState()
    const [isBbbSession, setIsBbbSession] = useState(false)
    const handleCopyStudentLink = () => {
        setOpen(true);
        navigator.clipboard.writeText(bbbLinks?.bbb_student_link);
    };
    const handleCopyModeratorLink = () => {
        setOpen(true);
        navigator.clipboard.writeText(bbbLinks?.bbb_mod_link);
    };
    useEffect(() => {
        if (edit) {
            setLoading(true)
            getEventDatas()
        }
    }, []);
    const getEventDatas = () => {
        api_get(`events/${id}`).then((result) => {
            setLoading(false);
            const payload = result.payload;
            let duration = Moment.duration(
                Moment(payload?.end_date).
                    diff(Moment(payload?.start_date)),
            );
            setAttachments(payload.content?.content_files)
            const hours = parseInt(duration.asHours());
            const minutes = parseInt(duration.asMinutes()) % 60;
            duration = `${hours}:${minutes}`;
            setValues({
                content: {
                    name: payload.content.name,
                    description: payload.content.description,
                    isDraft: payload.content.is_draft,
                    active: payload.content.active,
                    instructor: payload.content.instructor,
                    groupContents: payload.content?.group_contents,
                    contentStudentLevels: payload.content?.content_student_levels,
                    type: payload.content?.type,
                    isEventTitleApproved: 0,
                },
                startDate: moment(payload?.start_date, 'YYYY/MM/DD HH:mm').format("YYYY-MM-DD HH:mm"),
                endDate: moment(payload?.end_date, 'YYYY/MM/DD HH:mm').format("YYYY-MM-DD HH:mm"),
                isBigZoomEvent: payload?.is_big_zoom_event ? true : false,
                isFromCalendar: false,
                webinarAccount: payload.webinar_account,
                url: payload.url,
                canceled: payload?.canceled,
                subjectEvents: payload?.subject_events,
                reason: payload?.reason,
            });
            setTeacher(payload.content.instructor)
            setSubject(payload?.subject_events)
            setGroup(payload.content?.group_contents)
            setWebinar(payload.webinar_account)
            setUrl(payload.url)
            setName(payload.content.name)
            setLevels(payload.content?.content_student_levels)
            setActive(payload.content.active)
            setCancel(payload.canceled)

            if (payload?.webinar_account?.email?.includes("live")) {
                setIsBbbSession(true)
                api_get(`events/${id}/bbb-links`).then((data) => {
                    setBBBLinks(data?.payload)
                });
            }
        });
    }
    const handleChange = (event) => {
        setValues({
            ...values, content: {
                ...values.content, [event.target.name]: event.target.value,
            }
        });
    };

    const onFileChange = (files) => {
        if (files && files.length > 0) {
            setValues({
                ...values, content: {
                    ...values.content, contentFiles: files,
                }
            });
        }
    };
    const handleDescriptionChange = (value) => {
        setValues((valueState) => ({
            ...valueState,
            content: {
                ...valueState?.content,
                description: value?.editor?.getData(),
            },
        }));
    };

    const handleActiveCheckChange = (event) => {
        setActive(event.target.checked)
        setValues({
            ...values, content: {
                ...values.content, active: event.target.checked,
            }
        });
    };
    const handlePrivateCheckChange = (event) => {
        setIsDraft(event.target.checked)
        setValues({
            ...values, content: {
                ...values.content, isDraft: event.target.checked,
            }
        });
    };

    const handleCancelCheckChange = (event) => {
        setCancel(event.target.checked)
        setValues({ ...values, canceled: event.target.checked });
    };

    const handleCheckChangeInObject = (event, key) => {
        setValues({ ...values, isBigZoomEvent: event.target.checked });

    };
    const levelsChange = (options) => {
        setLevels(options)
        setValues({
            ...values, content: {
                ...values.content, contentStudentLevels: options,
            }
        });
    };

    const instructorChange = (value) => {
        if (value) {
            setTeacher((value))
            setValues({
                ...values, content: {
                    ...values.content, instructor: value,
                }
            });
        }
    };
    const webinarChange = (value) => {
        if (value) {
            setWebinar(value)
            setValues({ ...values, webinarAccount: value });
        }
    };

    const urlChange = (event) => {
        if (event) {
            setUrl(event.target.value)
            setValues({ ...values, url: event.target.value });
        }
    };

    const reasonChange = (event) => {
        if (event) {
            setReason(event.target.value)
            setValues({ ...values, reason: event.target.value });
        }
    };

    const groupsChange = (options) => {
        setGroup(options)
        setValues({
            ...values, content: {
                ...values.content, groupContents: options,
            }
        });
    };
    const subjectsChange = (options) => {
        setSubject(options)
        setValues({ ...values, subjectEvents: options });

    };

    const dateChange = (value) => {
        if (value) {
            setValues({ ...values, startDate: moment(value, 'YYYY/MM/DD HH:mm').format("YYYY-MM-DD HH:mm") });
            handleChangeDuration(duration, value.format('YYYY-MM-DD HH:mm'));
        }
    };
    const EndDateChange = (value) => {
        if (value) {
            setValues({ ...values, endDate: moment(value, 'YYYY/MM/DD HH:mm').format("YYYY-MM-DD HH:mm") });
        }
    };
    const showErrorMessage = (errors) => {
        Object.keys(errors).map((key, index) => {
            if (index == 0) {
                setAlertSeverity('error');
                errors.message ?
                    setAlertMessage(errors[0].message) :
                    setAlertMessage(errors[key][0]);
                setOpen(true);
            }
        });
    };
    const handleCreateTakiRoomSession = () => {
        api_post(`events/force-start-sess/${id}`).then((data) => {
            if (data?.code === 200) {
                setOpen(true)
            }
        }
        )
    }
    const showAlertMessage = (message) => {
        setAlertSeverity('error');
        setAlertMessage(message);
        setOpen(true);
    };
    const subjectFormat = (values) => {
        let newValues = JSON.parse(JSON.stringify(values));
        let newArr = [];
        let newArr2 = [];
        let newArr3 = [];
        let fileArr = [];

        values.subjectEvents &&
            values.subjectEvents.map(subject => {
                newArr.push({
                    'subject': subject.subject ? subject.subject.id : subject.id,
                });
            });
        values.subjectEvents = newArr;
        values.webinarAccount = values.webinarAccount.id;
        values.content.instructor = values.content.instructor.id;
        values.content.active = values?.content?.active == true ? 1 : 0;
        values.content.isDraft = values?.content?.isDraft == true ? 1 : 0;
        values.canceled = values?.canceled == true ? 1 : 0;
        values.content.type = values.content.type ? values.content.type.id : '';

        values.content.contentStudentLevels && values.content.contentStudentLevels.map(option => {
            newArr2.push({ 'studentLevel': option.student_level ? option.student_level.id : option.id })
        })
        values.content.contentStudentLevels = newArr2;

        values.content.groupContents && values.content.groupContents.map(option => {
            newArr3.push({ 'groups': option.groups ? option.groups.id : option.id })
        })
        values.content.groupContents = newArr3;

        if (values.content.contentFiles) {
            values.content.contentFiles.map(option => {
                fileArr.push({
                    'position': 0,
                    'file': {
                        'file': option,
                        'name': option.name,
                        'attachment': 1,
                        'solution': 0,
                        'document': 0,
                        'marks': 0,
                        'homework': 0,
                        'teacher': values.content.instructor.id
                    },
                })
            })
            values.content.contentFiles = fileArr
        }
        return values;
    };
    const clearDatas = (values) => {
        setValues({
            ...values,
            subjectEvents: subject,
            url: url,
            webinarAccount: webinar,
            canceled: canceled == true ? 1 : 0,
            content: {
                instructor: teacher,
                groupContents: group,
                contentStudentLevels: Levels,
                name: name,
                active: active == true ? 1 : 0,
                isDraft: isDraft == true ? 1 : 0,
            }
        });
    };
    const formValidation = (values) => {
        if (!values?.content?.name) {
            return { success: false, 'message': 'Name is missing' };
        }
        if (values?.content?.name.length < 3 || values?.content?.name?.length > 2000) {
            return { success: false, 'message': 'Name must be between 3 and 2000 characters' };
        }
        if (!values?.content?.instructor) {
            return { success: false, 'message': 'Instructor is missing' };
        }
        if (!values.webinarAccount) {
            return { success: false, 'message': 'WebinarAccount is missing' };
        }
        if (!values.subjectEvents || values.subjectEvents?.length <= 0) {
            return { success: false, 'message': 'Subjects are missing' };
        }
        if (values.canceled && !values.reason) {
            return { success: false, 'message': 'Reason is missing' };
        }
    };
    const handleSubmit = () => {
        const validation = formValidation(values);
        if (validation) {
            setAlertMessage(validation.message);
            setAlertSeverity('warning');
            setOpen(true);
        }
        else {
            const valuesState = subjectFormat(values);
            setLoading(true);
            const requestValues = mapStateToRequest(valuesState,
                ['content', 'subjectEvents'], ['isBigZoomEvent'],);
            api_post_serialize(`events/${id}`, requestValues, true).then((result) => {
                if (result.errors) {
                    showErrorMessage(result.errors)
                    clearDatas(values)

                }
                else if (result?.code !== 200 && result?.code !== 201) {
                    showAlertMessage(result?.message ? result.message : 'Technical error! Contact the Developers Team')
                    clearDatas(values)
                }
                else {
                    setAlertSeverity('success');
                    setAlertMessage('Event updated successfully');
                    setOpen(true);
                    setLoading(false);
                    window.location.href = '/contents/content-events';
                }
            });

        }
    };

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseLoading = () => {
        setLoading(false);
    }
    const checkIsZoomAccount = (email) => {
        return email?.includes('zoom') || email?.includes('ostedhy');
    };

    const handleChangeDuration = (duration, startD = null) => {
        const newValues = { ...values }
        if (newValues.startDate && duration) {
            let date = startD || newValues.startDate;
            setDuration(duration);
            const endDate = Moment(date).
                add(Moment.duration(duration).asMinutes(), 'minute').format('YYYY-MM-DD HH:mm');
            setValues({
                ...newValues,
                endDate: endDate,
                startDate: date,
            });
        }
    };
    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <form autoComplete="off" noValidate>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={8}>
                        <Card className={classes.root}>
                            <CardContent>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    name="name"
                                    onChange={handleChange}
                                    required
                                    value={values.content.name || ''}
                                    variant="outlined"
                                    size="small"
                                />
                                <Box mt={2} mb={1}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Description
                                    </Typography>
                                </Box>
                                <Paper variant="outlined">

                                    {(edit && values?.content?.description) ? (
                                        <DescriptionField
                                            values={values.content}
                                            handleBodyChange={handleDescriptionChange}
                                            value={values.content?.description}
                                        />
                                    ) : !edit ? (
                                        <DescriptionField
                                            values={values.content}
                                            handleBodyChange={handleDescriptionChange}
                                        />
                                    ) : values.content?.description == null ? (
                                        <DescriptionField
                                            values={values.content}
                                            handleBodyChange={handleDescriptionChange}
                                        />
                                    ) : values.content?.description == '' ? <DescriptionField
                                        values={values.content}
                                        handleBodyChange={handleDescriptionChange}
                                    /> :
                                        ""}
                                </Paper>
                                <Box mt={2} mb={1}>
                                    <TextField
                                        fullWidth
                                        label="Url"
                                        name="url"
                                        onChange={urlChange}
                                        required
                                        value={values.url || ''}
                                        variant="outlined"
                                        size="small"
                                    />
                                </Box>
                                {isBbbSession && <Button variant='contained' color='primary' onClick={handleCreateTakiRoomSession}>Create taki room session</Button>
                                }

                                {bbbLinks && <>  <Box mt={2} mb={1} style={{ display: "flex", alignItems: "center" }}>
                                    <TextField
                                        fullWidth
                                        label="Student Link"
                                        name="url"
                                        slotProps={{
                                            input: {
                                                readOnly: true,
                                            },
                                        }}
                                        required
                                        value={bbbLinks?.bbb_student_link || ''}
                                        variant="outlined"
                                        size="small"
                                    />
                                    <IconButton color="primary" onClick={handleCopyStudentLink}>
                                        <ShareIcon />
                                    </IconButton>
                                </Box>
                                    <Box mt={2} mb={1} style={{ display: "flex", alignItems: "center" }}>
                                        <TextField
                                            slotProps={{
                                                input: {
                                                    readOnly: true,
                                                },
                                            }}
                                            fullWidth
                                            label="Moderator Link"
                                            name="url"
                                            required
                                            value={bbbLinks?.bbb_mod_link || ''}
                                            variant="outlined"
                                            size="small"
                                        />
                                        <IconButton color="primary" onClick={handleCopyModeratorLink}>
                                            <ShareIcon />
                                        </IconButton>
                                    </Box></>}

                            </CardContent>
                        </Card>
                        <Box mt={3}>
                            <Card>
                                <CardHeader title="Date & Duration" />
                                <Divider />
                                <CardContent>
                                    <Box mt={0} mb={1}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} lg={6}>
                                                <LocalizationProvider dateAdapter={momentAdapter}>
                                                    <DateTimePicker
                                                        renderInput={(props) =>
                                                            <TextField
                                                                variant="outlined"
                                                                fullWidth
                                                                size="small"
                                                                {...props} />}
                                                        value={values.startDate}
                                                        autoOk
                                                        ampm={false}
                                                        inputFormat="DD/MM/yyyy HH:mm"
                                                        label="Start date"
                                                        onChange={dateChange}
                                                        name="startDate"
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <LocalizationProvider dateAdapter={momentAdapter}>
                                                    <DateTimePicker
                                                        renderInput={(props) =>
                                                            <TextField
                                                                variant="outlined"
                                                                fullWidth
                                                                size="small"
                                                                {...props} />}
                                                        value={values.endDate}
                                                        autoOk
                                                        ampm={false}
                                                        inputFormat="DD/MM/yyyy HH:mm"
                                                        label="End date"
                                                        onChange={EndDateChange}
                                                        name="endDate"
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            {/* <Grid item xs={12} lg={4}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    label="duration"
                                                    name="duration"
                                                    onChange={(event) => handleChangeDuration(
                                                        event.target.value)}
                                                    required
                                                    value={duration || ''}
                                                    variant="outlined"
                                                />
                                            </Grid> */}
                                        </Grid>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box mt={3}>
                            <Card>
                                <CardHeader title="Upload Zone" />
                                <Divider />
                                <CardContent>
                                    <Box mt={0} mb={1}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            Files
                                        </Typography>
                                    </Box>
                                    <FilesDropzone values={values} setValues={setValues}
                                        onFileChange={onFileChange} isMultiple={true} isattachment={true} isPdf={true} />
                                    <FilesList attachments={attachments} getElementDatas={getEventDatas} />
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Card className={classes.root}>
                            <CardHeader title="Basic" />
                            <Divider className={classes.mtop0} />
                            <CardContent className={classes.mtop0}>
                                <Box mt={0}>
                                    <AsyncPaginate
                                        loadOptions={getWebinars}
                                        onChange={webinarChange}
                                        getOptionLabel={(option) => option.email}
                                        getOptionValue={(option) => option.id}
                                        value={values.webinarAccount || ''}
                                        placeholder="Webinars"
                                        additional={{
                                            page: 1,
                                        }}
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                height: '53px',
                                            }),
                                            placeholder: (base) => ({
                                                ...base,
                                                color: '#000',
                                                fontSize: theme.typography.fontSize,
                                                fontFamily: theme.typography.fontFamily,
                                            }),
                                        }}
                                        menuPortalTarget={document.querySelector('body')}
                                    />
                                </Box>
                                {checkIsZoomAccount(
                                    values?.webinarAccount?.email) &&
                                    <Box mt={0}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.isBigZoomEvent}
                                                    onChange={(event) => handleCheckChangeInObject(
                                                        event, 'isBigZoomEvent')}
                                                    value={values.isBigZoomEvent}
                                                    name="isBigZoomEvent"
                                                    size="small"
                                                />
                                            }
                                            label="Is big zoom account ?"
                                        />
                                    </Box>
                                }
                                <Box mt={2}>
                                    <AsyncPaginate
                                        loadOptions={getTeachers}
                                        value={values.content.instructor}
                                        onChange={instructorChange}
                                        getOptionLabel={(option) => option.name + ' ' +
                                            option.last_name}
                                        getOptionValue={(option) => option.id}
                                        placeholder="Teachers"
                                        additional={{
                                            page: 1,
                                        }}
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                height: '53px',
                                            }),
                                            placeholder: (base) => ({
                                                ...base,
                                                color: '#000',
                                                fontSize: theme.typography.fontSize,
                                                fontFamily: theme.typography.fontFamily,
                                            }),
                                        }}
                                        menuPortalTarget={document.querySelector('body')}
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                        <Box mt={3}>
                            <Card className={classes.root}>
                                <CardHeader title="Organize" />
                                <Divider className={classes.mtop0} />
                                <CardContent className={classes.mtop0}>
                                    <Box mt={0}>
                                        <AsyncPaginate
                                            loadOptions={getSubjects}
                                            value={values.subjectEvents}
                                            onChange={subjectsChange}
                                            getOptionLabel={(option) => (option.subject ? option?.subject?.division?.name + '|' + option?.subject?.name : option?.division?.name + '|' + option.name) || ''}
                                            getOptionValue={(option) => option.subject ? option.subject.id : option.id}
                                            placeholder="Subjects"
                                            isMulti
                                            additional={{
                                                page: 1,
                                            }}
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    minHeight: '53px',
                                                }),
                                                placeholder: (base) => ({
                                                    ...base,
                                                    color: '#000',
                                                    fontSize: theme.typography.fontSize,
                                                    fontFamily: theme.typography.fontFamily,
                                                }),
                                            }}
                                            menuPortalTarget={document.querySelector('body')}
                                        />
                                    </Box>
                                    <Box mt={2}>
                                        <AsyncPaginate
                                            loadOptions={getGroups}
                                            value={values.content.groupContents}
                                            onChange={groupsChange}
                                            getOptionLabel={(option) => option.groups ? option.groups.name : option.name}
                                            getOptionValue={(option) => option.groups ? option.groups.id : option.id}
                                            placeholder="Groups"
                                            isMulti
                                            additional={{
                                                page: 1,
                                            }}
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    minHeight: '53px',
                                                }),
                                                placeholder: (base) => ({
                                                    ...base,
                                                    color: '#000',
                                                    fontSize: theme.typography.fontSize,
                                                    fontFamily: theme.typography.fontFamily,
                                                }),
                                            }}
                                            menuPortalTarget={document.querySelector('body')}
                                        />
                                    </Box>
                                    <Box mt={2}>
                                        <AsyncPaginate
                                            loadOptions={getLevels}
                                            value={values.content.contentStudentLevels}
                                            onChange={levelsChange}
                                            getOptionLabel={(option) => option.student_level ? option.student_level.name : option.name}
                                            getOptionValue={(option) => option.student_level ? option.student_level.id : option.id}
                                            placeholder="Levels"
                                            isMulti
                                            additional={{
                                                page: 1,
                                            }}
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    minHeight: '53px',
                                                }),
                                                placeholder: (base) => ({
                                                    ...base,
                                                    color: '#000',
                                                    fontSize: theme.typography.fontSize,
                                                    fontFamily: theme.typography.fontFamily,
                                                }),
                                            }}
                                            menuPortalTarget={document.querySelector('body')}
                                        />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box mt={3}>
                            <Card className={classes.root}>
                                <CardHeader title="Status" />
                                <Divider className={classes.mtop0} />
                                <CardContent className={classes.mtop0}>
                                    <Box mt={0}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.content?.active || ''}
                                                    onChange={handleActiveCheckChange}
                                                    value={values.content?.active || ''}
                                                    //name="active"
                                                    size="small"
                                                />
                                            }
                                            label="Active ?"
                                        />
                                    </Box>
                                    <Box mt={0}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.content.isDraft || ''}
                                                    onChange={handlePrivateCheckChange}
                                                    value={values.content.isDraft || ''}
                                                    //name="isDraft"
                                                    size="small"
                                                />
                                            }
                                            label="Private ?"
                                        />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box mt={3}>
                            <Card className={classes.root}>
                                <CardHeader title="Cancel Event?" />
                                <Divider className={classes.mtop0} />
                                <CardContent className={classes.mtop0}>
                                    <Box mt={0}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values?.canceled || ''}
                                                    onChange={handleCancelCheckChange}
                                                    value={values?.canceled || ''}
                                                    size="small"
                                                />
                                            }
                                            label="Canceled ?"
                                        />
                                    </Box>
                                    <Box mt={0}>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            variant="outlined"
                                            type="text"
                                            name="reason"
                                            label="Reason"
                                            className={classes.reasonTextField}
                                            value={values.reason || ''}
                                            onChange={reasonChange}
                                            required
                                            fullWidth
                                        />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
                <Box mt={3}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Save details
                    </Button>
                </Box>
            </form>
            <SnackBar
                open={open}
                message={message}
                severity={severity}
                handleClose={handleClose}
            />
            <LoadingApi open={loading} onClick={handleCloseLoading} />
        </div>
    );
};

FormEdit.propTypes = {
    className: PropTypes.string,
    edit: PropTypes.bool,
    handleCloseLoading: PropTypes.func,
    loading: PropTypes.bool,

};

export default FormEdit;
