import React, { useEffect, useState } from 'react';
import {
    TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker, LocalizationProvider } from '@material-ui/pickers';
import momentAdapter from '@material-ui/pickers/adapter/moment';
import { api_get } from "../utils/Api";
import PropTypes from 'prop-types';

const BillOfExchangeForm = (props) => {
    const { values, setValues, handleChange } = props;
    const [agencies, getAgencies] = useState([]);

    useEffect(() => {
        api_get(`agencies?perPage=50`).then((data) => {
            getAgencies(data.payload);
        });
    }, []);
    const agencyChange = (event, value) => {
        setValues({ ...values, 'agency': value ? value.id : null });
    };
    const dateChange = (value) => {
        setValues({ ...values, dueDate: value });
    };

    return (
        <div>
            <TextField
                autoFocus
                margin="dense"
                name="numBillOfExchange"
                id="numBillOfExchange"
                label="Bill of exchange Number"
                value={values.numBillOfExchange || ''}
                onChange={handleChange}
                fullWidth
            />
            <Autocomplete
                id="combo-box-demo"
                name="agency"
                options={agencies}
                value={values?.agency?.id}
                onChange={agencyChange}
                getOptionLabel={(option) => `${option.abbreviation}` !== 'null' ? `[${option.abbreviation}] ${option.fr_name}` : `${option.fr_name}`}
                renderInput={(params) => <TextField {...params}
                    label="Choose an agency"
                />
                }
            />
            <TextField
                autoFocus
                margin="dense"
                name="bankDomiciliationAddress"
                id="bankDomiciliationAddress"
                label="Bank Domiciliation Address"
                value={values.bankDomiciliationAddress || ''}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                autoFocus
                margin="dense"
                name="fullname"
                id="fullname"
                label="FullName"
                value={values.fullname || ''}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                autoFocus
                margin="dense"
                name="personalAddress"
                id="personalAddress"
                label="Personal Address"
                value={values.personalAddress || ''}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                fullWidth
                label="Phone Number"
                margin="dense"
                name="phone"
                id="phone"
                onChange={handleChange}
                type="number"
                value={values.phone || ''}
            />



            <LocalizationProvider dateAdapter={momentAdapter}>
                <DatePicker
                    label="Due Date"
                    id="dueDate"
                    value={values.dueDate}
                    onChange={dateChange}
                    format="MM-dd-yyyy"
                    renderInput={(props) => <TextField
                        {...props}
                        fullWidth
                        size="small"
                    />}
                />
            </LocalizationProvider>

        </div>
    );
}
BillOfExchangeForm.propTypes = {
    values: PropTypes.object,
    setValues: PropTypes.func,
    handleChange: PropTypes.func,
};
export default BillOfExchangeForm;
