import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  { accessor: 'id', name: 'id', label: 'Id', width: '10%', show: true },
  { accessor: 'penalty_detail.name', label: 'Penalty Detail', show: true },
  { accessor: 'event.id', label: 'Event', show: true },
  { accessor: 'instructor', label: 'Instructor', show: true, type: 'concatTeacher' },
  { accessor: 'confirmed', label: 'Confirmed', type: 'boolean', color: 'primary', show: true },
  { accessor: 'canceled', label: 'Canceled', type: 'boolean', color: 'primary', show: true },
];

const fieldSearchable = [
  'id',
];

const fieldFilterable = [
  { name: 'instructor.id', label: 'Instructor', type: 'instructorAutocomplete' },
  { name: 'event.id', label: 'Event', type: 'eventAutocomplete' },
  { name: 'penaltyDetail.id', label: 'Penalty Details', type: 'PenaltyDetailsAutocomplete' },
  { name: 'adminUser.id', label: 'Admin', type: 'adminAutocomplete' },
  { name: 'createdAt', label: 'Created At', type: 'dateRange' },
  { name: 'confirmed', label: 'Confirmed', type: 'boolean' },
  { name: 'canceled', label: 'Canceled', type: 'boolean' },
];

const EventPenaltyList = () => {
  return (
    <TableList
      columnsList={columnsList}
      fieldSearchable={fieldSearchable}
      fieldFilterable={fieldFilterable}
      url={`event-penalties`}
      exportUrl={`event-penalties/export`}
      searchMessage={'Search (id)'}
      pageLink={'/teacher-settings/event-penalties'}
      baseUrl={`event-penalties`}
      deleteUrl={`delete/many-event-penalties`}
      keyValue={"eventPenalties"}
      noShow={true}
      removeAdd={true}
      noEdit={true}
    />
  );
};

export default EventPenaltyList;
