import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import SnackBar from '../SnackBar';

import { api_put } from 'utils/Api';

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        padding: 0,
    },

}));

const ApproveReport = (props) => {
    const { className, handleRejectRepport, open, itemId, service, pageLink, restore, initializeData, rowsPerPage, item, ...rest } = props;
    const classes = useStyles();
    const [openAlert, setOpenAlert] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [severity, setAlertSeverity] = React.useState('success');


    const handleClose = () => {
        setOpenAlert(false);
    };

    const approveRepport = () => {


        if (service !== null) {
            api_put(`event-penalty-reports/${itemId}`, {
                status: "Approved",
                rejectReason: "",
                eventPenalty: item?.event_penalty.id,
                description: item?.description,
                user: item?.user?.id
            }).then((result) => {
                if (result?.code !== 200 && result?.code !== 201) {
                    setMessage('Error');
                    setOpenAlert(true);
                    setAlertSeverity('error');
                } else {
                    setMessage('Report approved');
                    setOpenAlert(true);
                    setAlertSeverity('success');
                    initializeData(rowsPerPage);
                    handleRejectRepport();
                }
            });
        }
    };

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Dialog
                open={open}
                onClose={handleRejectRepport}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Approve Report</DialogTitle>
                <DialogContent style={{ width: "450px" }}>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to approve this repport?
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRejectRepport} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={approveRepport} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <SnackBar
                open={openAlert}
                handleClose={handleClose}
                message={message}
                severity={severity}
            />
        </div>
    );
};

ApproveReport.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    handleRejectRepport: PropTypes.func,
    itemId: PropTypes.number,
};

export default ApproveReport;
