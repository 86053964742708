import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Delete from "../Action/Delete";
import CopyChapter from "./CopyChapter";
import CopyVideo from "./CopyVideo";
import Restore from "../Action/Restore";
import Duplicate from "views/ContentEvent/components/Duplicate";
import FlushCache from "./FlushCache";
import ResetProgress from "./ResetProgress";
import { useStore } from "../../contexts/JWTAuthContext";
import { checkPermission } from "../../utils/acl/aclRouter";
import AddFreeOffer from "./AddFreeOffer";
import CopySubject from "./CopySubject";
import { checkAdminName } from "../../utils/acl/aclPages";
import { api_get } from "../../utils/Api";
import { formatAdminRoleList } from "../../views/AdminRole/components/Form";
import CopyChapterIntoSubject from "./CopyChapterIntoSubject";
import RejectReport from "./RejectReport";
import ApproveReport from "./ApproveRepport";

const Action = (props) => {
  const {
    itemId,
    tableService,
    pageLink,
    unblock,
    noShow,
    noDelete,
    noEdit,
    replace,
    initializeData,
    offerAction,
    rowsPerPage,
    item,
    ...rest
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openDelete, setOpenDelete] = useState(false);
  const [openRejectReport, setOpenRejectRepport] = useState(false);
  const [openApproveReport, setOpenApproveRepport] = useState(false);

  const [openCopy, setOpenCopy] = useState(false);
  const [openCopyChapter, setOpenCopyChapter] = useState(false);
  const [openDuplicate, setDuplicate] = useState(false);
  const [openCache, setCache] = useState(false);
  const [openFree, setFree] = useState(false);
  const [openResetProgress, setResetProgress] = useState(false);
  const { user } = useStore();
  const routeDelete = window.location.pathname + "/delete";
  const routeShow = window.location.pathname + "/view";
  const routeEdit = window.location.pathname + "/edit";

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopy = () => {
    setOpenCopy(!openCopy);
  };
  const handleCopyChapter = () => {
    setOpenCopyChapter(!openCopyChapter);
  };
  const handleDelete = () => {
    setOpenDelete(!openDelete);
  };
  const handleRejectRepport = () => {
    setOpenRejectRepport(!openRejectReport);
  };
  const handleApproveRepport = () => {
    setOpenApproveRepport(!openApproveReport);
  };
  const handleDuplicate = () => {
    setDuplicate(!openDuplicate);
  };
  const handleCache = () => {
    setCache(!openCache);
  };
  const handleFreeOffer = () => {
    setFree(!openFree);
  };
  const handleResetProgress = () => {
    setResetProgress(!openResetProgress);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "fit-content",
          },
        }}
      >
        {!noShow &&
          user &&
          checkPermission(routeShow, user.roles, user?.admin_groups) && (
            <MenuItem component={Link} to={`${pageLink}/view/${itemId}`}>
              Show
            </MenuItem>
          )}
        {!noEdit &&
          user &&
          checkPermission(routeEdit, user.roles, user?.admin_groups) && (
            <MenuItem component={Link} to={`${pageLink}/edit/${itemId}`}>
              Edit
            </MenuItem>
          )}

        {!noDelete &&
          user &&
          checkPermission(routeDelete, user.roles, user?.admin_groups) && (
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
          )}
        {pageLink == "/user-settings/banned-users" && (
          <MenuItem onClick={handleDelete}>Unblock</MenuItem>
        )}
        {pageLink == "/teacher-settings/event-penalty-reports" && (
          <MenuItem onClick={handleRejectRepport}>Reject</MenuItem>
        )}
        {pageLink == "/teacher-settings/event-penalty-reports" && (
          <MenuItem onClick={handleApproveRepport}>Approve</MenuItem>
        )}
        {pageLink.includes("archives/") && (
          <MenuItem onClick={handleDelete}>Restore</MenuItem>
        )}

        {pageLink.includes("chapters") && (
          <MenuItem onClick={handleCopy}>
            Copy&nbsp;Chapter&nbsp;Into&nbsp;Subject
          </MenuItem>
        )}
        {pageLink.includes("chapters") && (
          <MenuItem onClick={handleCopyChapter}>
            Copy&nbsp;Chapter&nbsp;Into&nbsp;Chapter
          </MenuItem>
        )}
        {pageLink.includes("/content-videos") && (
          <MenuItem onClick={handleCopy}>Copy</MenuItem>
        )}
        {pageLink.includes("/subjects") && (
          <MenuItem onClick={handleCopy}>Copy</MenuItem>
        )}
        {pageLink == "/hessaty/traineeships" && (
          <MenuItem
            onClick={() =>
              window.open(`content-events/create?chapter_id=${itemId}`)
            }
          >
            Event
          </MenuItem>
        )}
        {pageLink == "/users/students" && (
          <div>
            <MenuItem onClick={handleCache}>Flush Cache</MenuItem>
            {user &&
              (user.roles.includes("ROLE_SUPER_ADMIN") ||
                checkAdminName(user.admin_groups, "Admin-SAV") ||
                checkAdminName(user.admin_groups, "confirmed_developer")) && (
                <MenuItem onClick={handleResetProgress}>
                  Reset Progress
                </MenuItem>
              )}
          </div>
        )}
        {offerAction && (
          <MenuItem onClick={handleFreeOffer}>Trial/Trial+</MenuItem>
        )}
        {pageLink == "/hessaty/content-events" && (
          <div>
            <MenuItem
              onClick={() =>
                window.open(
                  `/contents/content-videos/create?event_id=${itemId}`
                )
              }
            >
              Recording
            </MenuItem>
            <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
          </div>
        )}
        {pageLink == "/contents/content-events" && (
          <div>
            <MenuItem
              onClick={() =>
                window.open(
                  `/contents/content-videos/create?event_id=${itemId}`
                )
              }
            >
              Recording
            </MenuItem>
            <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
          </div>
        )}
      </Menu>
      <Delete
        open={openDelete}
        deblock={unblock}
        handleDelete={handleDelete}
        itemId={itemId}
        replace={replace}
        service={tableService}
        pageLink={pageLink}
        replac={replace}
        rowsPerPage={rowsPerPage}
        initializeData={initializeData}
      />
      <RejectReport
        open={openRejectReport}
        handleRejectRepport={handleRejectRepport}
        itemId={itemId}
        item={item}
        service={tableService}
        pageLink={pageLink}
        replac={replace}
        rowsPerPage={rowsPerPage}
        initializeData={initializeData}
      />
      <ApproveReport
        open={openApproveReport}
        handleRejectRepport={handleApproveRepport}
        itemId={itemId}
        item={item}
        service={tableService}
        pageLink={pageLink}
        replac={replace}
        rowsPerPage={rowsPerPage}
        initializeData={initializeData}
      />
      <FlushCache
        open={openCache}
        handleCache={handleCache}
        itemId={itemId}
        service={tableService}
        pageLink={pageLink}
        initializeData={initializeData}
        rowsPerPage={rowsPerPage}
      />
      <AddFreeOffer
        open={openFree}
        handleOffer={handleFreeOffer}
        itemId={itemId}
        service={tableService}
        pageLink={pageLink}
        initializeData={initializeData}
        rowsPerPage={rowsPerPage}
      />
      {pageLink.includes("archives/") && (
        <Restore
          open={openDelete}
          handleRestore={handleDelete}
          itemId={itemId}
          service={tableService}
          pageLink={pageLink}
          initializeData={initializeData}
          rowsPerPage={rowsPerPage}
        />
      )}

      {pageLink.includes("/chapters") && (
        <CopyChapter
          open={openCopy}
          setOpen={setOpenCopy}
          itemId={itemId}
          itemName={item.name}
          handleCopy={handleCopy}
          service={tableService}
          pageLink={pageLink}
          initializeData={initializeData}
          rowsPerPage={rowsPerPage}
        />
      )}
      {pageLink.includes("/chapters") && (
        <CopyChapterIntoSubject
          open={openCopyChapter}
          setOpen={setOpenCopyChapter}
          itemId={itemId}
          itemName={item.name}
          handleCopy={handleCopyChapter}
          service={tableService}
          pageLink={pageLink}
          initializeData={initializeData}
          rowsPerPage={rowsPerPage}
        />
      )}
      {pageLink.includes("/content-videos") && (
        <CopyVideo
          open={openCopy}
          item={item}
          itemId={itemId}
          itemName={item?.content?.name}
          handleCopy={handleCopy}
          service={tableService}
          pageLink={pageLink}
          initializeData={initializeData}
          rowsPerPage={rowsPerPage}
          setOpen={setOpenCopy}
        />
      )}

      {pageLink.includes("/subjects") && (
        <CopySubject
          setOpen={setOpenCopy}
          open={openCopy}
          item={item}
          itemId={itemId}
          itemName={item?.name}
          handleCopy={handleCopy}
          service={tableService}
          pageLink={pageLink}
          initializeData={initializeData}
          rowsPerPage={rowsPerPage}
        />
      )}

      <Duplicate
        open={openDuplicate}
        handleDuplicate={handleDuplicate}
        itemId={itemId}
        initializeData={initializeData}
      />

      <ResetProgress
        open={openResetProgress}
        handleResetProgress={handleResetProgress}
        itemId={itemId}
        service={tableService}
        pageLink={pageLink}
        initializeData={initializeData}
        rowsPerPage={rowsPerPage}
      />
    </div>
  );
};

Action.propTypes = {
  className: PropTypes.string,
  itemId: PropTypes.number,
  noShow: PropTypes.bool,
};

export default Action;
